<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-plus" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Add New SHS
                    </div>
                    <div class="text-sm text-500">
                      Create new shs record
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div class="grid">
                   
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">
                        SHS Name *
                      </div>
                      <div id="ctrl-shs_name-holder">
                        <InputText
                          ref="ctrlshs_name"
                          v-model.trim="formData.shs_name"
                          label="SHS Title"
                          type="text"
                          placeholder="SHS Name"
                          class="w-full"
                          :class="getErrorClass('shs_name')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">
                        Email *
                      </div>
                      <div id="ctrl-email-holder">
                        <InputText
                          ref="ctrlemail"
                          v-model.trim="formData.email"
                          label="Email"
                          type="text"
                          placeholder="Email"
                          class="w-full"
                          :class="getErrorClass('email')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Region *</div>
                      <div id="ctrl-region-holder">
                        <Dropdown
                          class="w-full"
                          :class="getErrorClass('region')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrlregion"
                          v-model="formData.region"
                          :options="$menus.regionsItems"
                          label="Region"
                          placeholder="Select ..."
                        >
                        </Dropdown>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">District *</div>
                      <div id="ctrl-district-holder">
                        <Dropdown
                          class="w-full"
                          :class="getErrorClass('district')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrlregion"
                          v-model="formData.district"
                          :options="filteredDistricts"
                          label="District"
                          placeholder="Select ..."
                        >
                        </Dropdown>
                      </div>
                    </div>
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">
                        Location *
                      </div>
                      <div id="ctrl-location-holder">
                        <InputText
                          ref="ctrllocation"
                          v-model.trim="formData.location"
                          label="Location"
                          type="text"
                          placeholder="Location"
                          class="w-full"
                          :class="getErrorClass('location')"
                        >
                        </InputText>
                      </div>
                    </div>
                   
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Gender *</div>
                      <div id="ctrl-gender-holder">
                        <Dropdown
                          class="w-full"
                          :class="getErrorClass('gender')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrlgender"
                          v-model="formData.gender"
                          :options="$menus.shsgenderItems"
                          label="Gender"
                          placeholder="Select ..."
                        >
                        </Dropdown>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Residency *</div>
                      <div id="ctrl-residency-holder">
                        <Dropdown
                          class="w-full"
                          :class="getErrorClass('residency')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrlresidency"
                          v-model="formData.residency"
                          :options="$menus.shsresidencyItems"
                          label="Residency"
                          placeholder="Select ..."
                        >
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
  decimal,
  email,
  integer,
  ipAddress,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  sameAs,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { AddPageMixin } from "../../mixins/addpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addshslistPage",
  components: {},
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "shs_list",
    },
    routeName: {
      type: String,
      default: "shs_listadd",
    },
    apiPath: {
      type: String,
      default: "shs_list/add",
    },
  },
  data() {
    return {
      formData: {
        shs_name: "",
        region: "",
        district: "",
        location: "",
        gender: "",
        residency: "",
        email: "",
       
      },
     
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add SHS";
      },
    },
    filteredDistricts() {
    if (!this.formData.region) {
      return [];
    }

    const region = this.$menus.districtsItems.find(r => r.label === this.formData.region);

    // Return the items if the region is found, otherwise return an empty array
    return region ? region.items : [];
  }
  },
  validations() {
    let formData = {
      shs_name: { required },
      region: { required },
      district: { required },
      location: {required},
      gender: { required },
      residency: {  },
      email: {},
     
    };
    return { formData };
  },
  methods: {
    ...mapActions("shs_list", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["shs_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          // if (this.redirect) this.navigateTo(`/shs_list`);
          this.$router.push(this.$route.path);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
     
      
    },
    resetForm() {
      this.formData = {
        shs_name: "",
        region: "",
        district: "",
        location: "",
        gender: "",
        residency: "",
        email: "",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  


  watch: {
    'formData.region': function(newRegion, oldRegion) {
    if(newRegion !== oldRegion) {
      this.formData.district = '';
    }
  }
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
